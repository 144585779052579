import axios from 'axios';

const REFRESH_URL_TOKEN = `${process.env.VUE_APP_API_BASE_URL}/api/users/refresh-token`;

const LOCAL_STORAGE_TOKEN = 'access_token';
const LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

export const TokenStorage = {
    getToken(): any {
        if (localStorage.getItem(LOCAL_STORAGE_TOKEN) !== null) {
            return localStorage.getItem(LOCAL_STORAGE_TOKEN);
        }
    },
    getRefreshToken() {
        return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
    },
    clear() {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    },
    storeToken(token: string) {
        localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
    },
    storeRefreshToken(refreshToken: string) {
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
    },
    async getNewToken() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(REFRESH_URL_TOKEN, { refreshToken: this.getRefreshToken() });
                this.storeToken(response.data.token);
                this.storeRefreshToken(response.data.refreshToken);

                resolve(response.data.token);
            } catch (error) {
                reject(error);
            }
        });
    },
    isAuthenticated() {
        return this.getToken() ? true : false;
    },
};
