import axios from 'axios';
import '../../axiosInterceptor';
import { TokenStorage } from '@/tokenStorage';
import { EventBus } from '@/utilities/event-bus';

const USER_ID_KEY = 'access_token';

const LOGIN_URL = `${process.env.VUE_APP_API_BASE_URL}/auth`;
const RAFFLE = `${process.env.VUE_APP_API_BASE_URL}`;
const SINGLE_RAFFLE = `${process.env.VUE_APP_API_BASE_URL}/:id`;

export const UsersAPI = {
    setUserId(userId: string) {
        localStorage.setItem(USER_ID_KEY, userId);
    },
    getUserId() {
        return localStorage.getItem(USER_ID_KEY);
    },
    deleteUserId() {
        localStorage.removeItem(USER_ID_KEY);
    },
    async getWorkSpaces(){
      try {
          EventBus.$emit('load');
          const response = await axios.get(`${process.env.VUE_APP_WORKSPACES_URL}/workspaces`);
          EventBus.$emit('endLoad');
          return response.data;
      } catch (error) {
          EventBus.$emit('endLoad');
          throw Error(error.response.data.msg);
      }
    },
    async getWorkSpaceSettings(){
        try {
            EventBus.$emit('load');
            const response = await axios.get(`${process.env.VUE_APP_WORKSPACES_URL}/workspace/settings`);
            EventBus.$emit('endLoad');
            return response.data;
        } catch (error) {
            EventBus.$emit('endLoad');
            throw Error(error.response.data.msg);
        }
    },
    async updateWorkSpaceSettings(data){
        try {
            EventBus.$emit('load');
            const response = await axios.put(`${process.env.VUE_APP_WORKSPACES_URL}/workspace/settings`,data);
            EventBus.$emit('endLoad');
            return response.data;
        } catch (error) {
            EventBus.$emit('endLoad');
            throw Error(error.response.data.msg);
        }
    },
    async login(username: string, password: string, workspace: string) {
        try {
            EventBus.$emit('load');
            const response = await axios.post(`${LOGIN_URL}/${workspace}`, {}, { auth: { username, password } });
            EventBus.$emit('endLoad');
            TokenStorage.storeToken(response.data.access_token);
            // TokenStorage.storeRefreshToken(response.data.refreshToken);
            this.setUserId(response.data.access_token);
            return response.data;
        } catch (error) {
            EventBus.$emit('endLoad');
            throw Error(error.response.data.msg);
        }
    },
    async createRaffle(name: string, description: string, mainColumnsIndexes: number[],
        winnersQuantity: number, surrogatesQuantity: number, contentType: string, components: any) {
        try {
            const response = await axios.post(RAFFLE, {
                name,
                description,
                settings: {
                    main_columns_indexes: mainColumnsIndexes,
                    winners_quantity: winnersQuantity,
                    surrogates_quantity: surrogatesQuantity,
                    components: components,
                },
                content_type: contentType,
            },
            );
            return response.data;
        } catch (error) {
            throw Error(error.response.data.msg);
        }
    },
    async getRaffle(raffleId: string) {
        try {
            const response = await axios.get(SINGLE_RAFFLE.replace(':id', raffleId));
            return response.data;
        } catch (error) {
            throw Error(error.response.data.msg);
        }
    },
    async updateRaffle(raffleId: string, name: string, description: string, mainColumnsIndexes: number[],
        winnersQuantity: number, surrogatesQuantity: number, components: any) {
        try {
            const response = await axios.put(SINGLE_RAFFLE.replace(':id', raffleId), {
                name,
                description,
                settings: {
                    main_columns_indexes: mainColumnsIndexes,
                    winners_quantity: winnersQuantity,
                    surrogates_quantity: surrogatesQuantity,
                    components: components,
                }
            },
            );
        } catch (error) {
            throw Error(error.response.data.msg);
        }
    },
    async getPreSignedUrl(raffleId: string, contentType: string, resourceType: string) {
        try {
            const response = await axios.get(`${RAFFLE}/${raffleId}/resources/${resourceType}/presigned-url?content_type=${contentType}`);
            return response.data;
        } catch (error) {
            throw Error(error.response.data.msg);
        }
    },
    async getDefaultImagePreSignedUrl(contentType: string) {
        try {
            const response = await axios.get(`${process.env.VUE_APP_WORKSPACES_URL}/workspace/settings/default-background/presigned-url?content_type=${contentType}`);
            return response.data;
        } catch (error) {
            throw Error(error.response.data.msg);
        }
    },
    // tslint:disable-next-line:variable-name
    async saveFile(presigned_url: string, file: any) {
        try {
            const instance = axios.create();
            instance.defaults.headers.common = {};
            let response = await instance.request({
                method: 'put',
                url: presigned_url,
                headers: {
                    'Content-Type': file.type,
                },
                data: file,
            });
            return response;
        } catch (error) {
            throw Error(error.response.data.msg);
        }
    },
};
