



























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { EventBus } from "@/utilities/event-bus";

@Component
export default class RandomSelector extends Vue {
  @Prop({ type: Array }) private items!: [{ _id: string; index: number }];
  @Prop({ type: Number, default: 15 }) private time!: number;
  @Prop({ type: String, default: "" }) private raffle_id!: string;

  private itemInx: number = 0;
  private showItems: any = [];
  private raffle: any = {};
  private raffleData: any = {};
  private gotWinner: boolean = false;
  private spin: boolean = false;
  private showWinner: boolean = false;
  private confetti: boolean = false;
  private raffleAgain: any = false;
  private winnersAndSurrogates: any = {};
  private canShowWinners: string[] = ["raffled","canceled","closed"];
  private winnersColor: string = '#7A787B';
  private winnersBGColor: string = '#D9272E';
  private surrogatesColor: string = '#7A787B';
  private surrogatesBGColor: string = '#D9272E';
  private participantsColor: string = '#D9272E';
  private buttonColor: string = '#ffffff';
  private buttonBGColor: string = '#D9272E';
  private winnersLabel: string = 'GANADORES';
  private surrogatesLabel: string = 'SUPLENTES';
  private buttonText: string = 'INICIAR SORTEO';

  public async mounted() {
    this.raffleAgain = this.$router.currentRoute.params.raffleAgain
      ? this.$router.currentRoute.params.raffleAgain
      : false;
    await this.getRaffleData();
    if (this.wasRaffled) {
      if (this.raffleAgain) {
        await this.getSamples();
      } else {
        await this.getWinner();
      }
    } else {
      await this.getSamples();
    }
  }
  get winner(): number {
    return this.showItems.length - 1;
  }
  get mainColumns(): [] {
    return this.raffle ? this.raffle.settings.main_columns_indexes : [];
  }
  get dotColumns(): [] {
    let cols: any = [];
    if (this.winnersAndSurrogates) {
      this.winnersAndSurrogates.winners.forEach((e: any) =>
        cols.push({ value: e, type: "winner" })
      );
      this.winnersAndSurrogates.surrogates.forEach((e: any) =>
        cols.push({ value: e, type: "surrogate" })
      );
    }
    return cols;
  }
  get wasRaffled(): boolean {
    return this.raffleData ? this.canShowWinners.includes(this.raffleData.status) : false;
  }
  get selectedColData(): any {
    return this.showItems ? this.showItems[this.itemInx] : [];
  }
  get backgroundUrl(): string {
    return this.raffleData && this.raffleData.resources && this.raffleData.resources.background ? this.raffleData.resources.background : null;
  }
  trim (str) {
    return str.replace(/^\s+|\s+$/gm,'');
  }
  rgbaToHex (rgba) {
    let inParts = rgba.substring(rgba.indexOf("(")).split(","),
        r = parseInt(this.trim(inParts[0].substring(1)), 10),
        g = parseInt(this.trim(inParts[1]), 10),
        b = parseInt(this.trim(inParts[2]), 10);
    let outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
    ];
    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = '0' + part;
      }
    })
    return ('#' + outParts.join(''));
  }
  public async getRaffleData(): Promise<any> {
    EventBus.$emit("load");
    return axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/${this.raffle_id}/`)
      .then((response: any) => {
        this.raffleData = response.data;
        this.raffle = response.data;
        const settings = this.raffleData.settings
        const labels = settings.components.labels
        this.winnersLabel = labels.winners.text
        this.surrogatesLabel = labels.surrogates.text
        this.winnersColor = this.rgbaToHex(labels.winners.color)
        this.winnersBGColor = this.rgbaToHex(labels.winners.background_color)
        this.surrogatesColor = this.rgbaToHex(labels.surrogates.color)
        this.surrogatesBGColor = this.rgbaToHex(labels.surrogates.background_color)
        this.participantsColor = labels.participants ? this.rgbaToHex(labels.participants.color) : '#D9272E'
        const buttons = settings.components.buttons
        this.buttonText = buttons.start_raffle.text
        this.buttonColor = this.rgbaToHex(buttons.start_raffle.color)
        this.buttonBGColor = this.rgbaToHex(buttons.start_raffle.background_color)
        EventBus.$emit("endLoad");
      })
      .catch((err: any) => {
        console.log(err);
        EventBus.$emit("endLoad");
      });
  }
  public async getSamples(): Promise<any> {
    EventBus.$emit("load");
    return axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/${this.raffle_id}/sample`)
      .then((response: any) => {
        this.raffle = response.data;
        this.showItems = this.raffle.records;
        EventBus.$emit("endLoad");
      })
      .catch((err: any) => {
        console.log(err);
        EventBus.$emit("endLoad");
      });
  }
  public async getWinner(): Promise<any> {
    EventBus.$emit("load");
    return axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/${this.raffle_id}/results`)
      .then((response: any) => {
        this.winnersAndSurrogates = response.data;
        this.showItems.push(this.winnersAndSurrogates.winners[0]);
        this.showItems = this.dotColumns;
        this.gotWinner = true;
        EventBus.$emit("endLoad");
      })
      .catch((err: any) => {
        console.log(err);
        EventBus.$emit("endLoad");
      });
  }
  public async newWinner(): Promise<any> {
    EventBus.$emit("load");
    return axios
      .post(`${process.env.VUE_APP_API_BASE_URL}/${this.raffle_id}/execute`)
      .then((response: any) => {
        this.winnersAndSurrogates = response.data;
        this.showItems.push(this.winnersAndSurrogates.winners[0]);
        this.gotWinner = true;
        EventBus.$emit("endLoad");
      })
      .catch((err: any) => {
        EventBus.$emit("endLoad");
        console.log(err);
      });
  }
  public async startRaffle(): Promise<any> {
    try {
     const response = await axios.patch(
        `${process.env.VUE_APP_API_BASE_URL}/${this.raffle_id}/`,
        { status: "raffle-started" }
      );
     if (response) {
      await this.newWinner();
      let count: number = 0;
      this.spin = true;
      let movement = setInterval(() => {
        if (this.itemInx + 1 <= this.showItems.length - 1) {
          this.itemInx++;
        } else {
          this.itemInx = 0;
        }
      }, 100);
      let timer = setInterval(() => {
        if (count < this.time) {
          count++;
        }
        if (count === this.time && this.gotWinner) {
          clearInterval(movement);
          clearInterval(timer);
          this.finalMovement();
        }
      }, 1000);
     } 
    } catch (error) {
      console.log(error);
    }
  }
  public finalMovement(): void {
    this.itemInx = 0;
    this.showItems = this.dotColumns;
    this.showWinner = true;
    this.confetti = true;
    setTimeout(() => {
      this.confetti = false;
    }, 20000);
  }
  public nextDot(i: number): void {
    this.itemInx = i;
  }
  public previousCard(): void {
    const tem = this.itemInx - 1;
    if (tem < 0) this.itemInx = this.showItems.length - 1;
    else this.itemInx--;
  }
  public nextCard(): void {
    const tem = this.itemInx + 1;
    if (tem >= this.showItems.length) this.itemInx = 0;
    else this.itemInx++;
  }
}
