<template>
  <div class="vertical-container">
    <h5 class="header-color">Actualizar Sorteo</h5>
    <div class="row">
      <div class="col s8 offset-s2 m8 offset-m2 l6 offset-l3 card-panel">
        <form v-on:submit="formSubmit">
          <div class="row mt-1">
            <div class="input-field col s6">
              <input class="validate" id="name" type="text" v-model="name" required />
              <label for="name" class="active">Nombre</label>
            </div>
            <div class="input-field col s6">
              <input class="validate" id="description" type="text" v-model="description" required />
              <label for="description">Descripción</label>
            </div>
            <div class="input-field col s6">
              <input
                class="validate"
                id="winnersNumber"
                type="number"
                min="1"
                v-model="winnersNumber"
                required
              />
              <label for="winnersNumber">Número de ganadores</label>
            </div>
            <div class="input-field col s6">
              <input
                class="validate"
                id="surrogatesNumber"
                type="number"
                min="0"
                v-model="surrogatesNumber"
                required
              />
              <label for="surrogatesNumber">Número de suplentes</label>
            </div>
            <div class="input-field col s12">
              <multiselect
                v-model="columnsValue"
                placeholder="Buscar nombre de columna"
                selectLabel="Seleccionar"
                selectedLabel="Seleccionado"
                deselectLabel="Remover"
                openDirection="top"
                label="name"
                :max="2"
                :closeOnSelect="false"
                track-by="value"
                :options="columnsOptions"
                :multiple="true"
                :taggable="true"
                :max-height="250"
                required
              >
                <template v-slot:noOptions>No se ha cargado archivo</template>
                <template v-slot:maxElements>Máximo de columnas seleccionadas</template>
              </multiselect>
            </div>
          </div>
          <div class="file-field input-field col s6 offset-s3">
            <div class="waves-effect waves-light btn at-color btn-floating">
              <i class="material-icons">file_upload</i>
              <input
                  type="file"
                  id="bgimage"
                  ref="bgimage"
                  accept="image/png"
                  v-on:change="processImage()"
              />
            </div>
            <div class="file-path-wrapper">
              <input class="file-path validate" type="text" v-model="imageName" placeholder="Seleccionar imagen de fondo"/>
            </div>
            <p>*Si no quiere cambiar la imagen dejar en blanco</p>
          </div>
          <div class="row"></div>
          <div class="row" style="padding: 0 calc(0.75rem + 10px)">
            <v-drop :blocked="true" :preview-file="displayImg" message="Inserte la imagen de fondo por defecto" height="300px"></v-drop>
          </div>
          <div class="row mt-1">
            <h5 class="header-color">Labels</h5>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center; margin-bottom: 0">
            <div class="input-field col s6" style="width: 100%">
              <input class="validate" id="winners" type="text" v-model="winnersLabel" required />
              <label for="winners">Texto Ganadores</label>
            </div>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center; margin-top: 0">
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de título ganadores</p>
              <input class="validate" type="color" id="winner-color" style="width: 100%" v-model="winnersColor">
            </div>
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de texto ganadores</p>
              <input class="validate" type="color" id="winner-bg-color" style="width: 100%" v-model="winnersBGColor">
            </div>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center; margin-top: 20px">
            <div class="input-field col s6" style="width: 100%">
              <input class="validate" id="surrogates" type="text" v-model="surrogatesLabel" required />
              <label for="surrogates">Texto Suplentes</label>
            </div>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center">
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de título suplentes</p>
              <input class="validate" type="color" style="width: 100%" v-model="surrogatesColor">
            </div>
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de texto suplentes</p>
              <input class="validate" type="color" id="surrogate-bg-color" style="width: 100%" v-model="surrogatesBGColor">
            </div>
          </div>
          <div class="row mt-1">
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de texto participantes</p>
              <input class="validate" type="color" id="participants-bg-color" style="width: 100%" v-model="participantsColor">
            </div>
          </div>
          <div class="row mt-1">
            <h5 class="header-color">Botones</h5>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center">
            <div class="input-field col s6" style="width: 100%">
              <input class="validate" id="button-label" type="text" v-model="buttonText" required />
              <label for="button-label">Texto de Iniciar Sorteo</label>
            </div>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center">
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de texto</p>
              <input class="validate" type="color" style="width: 100%" v-model="buttonColor">
            </div>
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de fondo</p>
              <input class="validate" type="color" style="width: 100%" v-model="buttonBGColor">
            </div>
          </div>
          <div class="row">
            <div class="input-field col s4 offset-s4">
              <button class="btn at-color lighten-1 col s12">Actualizar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script >
import { UsersAPI } from "../store/api/users";
import Multiselect from "vue-multiselect";
import Papa from "papaparse";
import {EventBus} from "@/utilities/event-bus";
import Dropify from "@/components/Dropify";

export default {
  name: "UpdateRaffle",
  components: {
    Multiselect,
    'v-drop': Dropify
  },
  data() {
    return {
      name: "",
      id: "",
      description: "",
      file: "",
      filename: "",
      columnsValue: [],
      columnsOptions: [],
      surrogatesNumber: "",
      winnersNumber: "",
      bgImage: '',
      displayImg: '',
      imageName: '',
      firstLoad: true,
      winnersColor: '#7A787B',
      winnersBGColor: '#D9272E',
      surrogatesColor: '#7A787B',
      surrogatesBGColor: '#D9272E',
      participantsColor: '#D9272E',
      buttonColor: '#ffffff',
      buttonBGColor: '#D9272E',
      winnersLabel: 'GANADORES',
      surrogatesLabel: 'SUPLENTES',
      buttonText: 'INICIAR SORTEO',
    };
  },
  async mounted() {
    const raffle = await UsersAPI.getRaffle(
      this.$router.currentRoute.params.id
    );
    this.id = raffle.id;
    this.name = raffle.name;
    this.description = raffle.description;
    this.surrogatesNumber = raffle.settings.surrogates_quantity;
    this.winnersNumber = raffle.settings.winners_quantity;
    const settings = raffle.settings
    const labels = settings.components.labels
    if(labels) {
      this.winnersLabel = labels.winners.text
      this.surrogatesLabel = labels.surrogates.text
      this.winnersColor = this.rgbaToHex(labels.winners.color)
      this.winnersBGColor = this.rgbaToHex(labels.winners.background_color)
      this.surrogatesColor = this.rgbaToHex(labels.surrogates.color)
      this.surrogatesBGColor = this.rgbaToHex(labels.surrogates.background_color)
    }
    const buttons = settings.components.buttons
    if (buttons) {
      this.buttonText = buttons.start_raffle.text
      this.buttonColor = this.rgbaToHex(buttons.start_raffle.color)
      this.buttonBGColor = this.rgbaToHex(buttons.start_raffle.background_color)
    }
    EventBus.$emit('load');
    const response = await fetch(raffle.resources.background);
    const blob = await response.blob();
    this.displayImg = new File([blob], 'display', {type: blob.type});
    EventBus.$emit('endLoad');
    this.$nextTick(() => {
      M.updateTextFields();
    });
    let i = 0;
    for (const header of raffle.headers) {
      if (raffle.settings.main_columns_indexes.includes(i)) {
        this.columnsValue.push({
          name: header,
          value: i,
        });
      }
      i++;
    }
    i = 0;
    for (const header of raffle.headers) {
      this.columnsOptions.push({
        name: header,
        value: i,
      });
      i++;
    }
  },
  methods: {
    parseData(url, callBack) {
      Papa.parse(url, {
        download: true,
        dynamicTyping: true,
        preview: 1, // Reading only the first line of the file
        complete: function (results) {
          callBack(results.data);
        },
      });
    },
    setColumnsOption(data) {
      let i = 0;
      this.columnsOptions = [];
      this.columnsValue = [];
      for (const header of data[0]) {
        this.columnsOptions.push({
          name: header,
          value: i,
        });
        i++;
      }
    },
    processFile() {
      this.parseData(this.$refs.file.files[0], this.setColumnsOption);
      this.file = this.$refs.file.files[0];
      this.filename = this.$refs.file.files[0].name;
    },
    processImage(){
      this.bgImage = this.$refs.bgimage.files[0];
      this.displayImg = this.$refs.bgimage.files[0];
      this.imageName = this.$refs.bgimage.files[0].name;
    },
    convertHexToRGBA(hexCode){
      let hex = hexCode.replace('#', '');
      if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
      }
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r},${g},${b},1)`;
    },
    trim (str) {
      return str.replace(/^\s+|\s+$/gm,'');
    },
    rgbaToHex (rgba) {
      let inParts = rgba.substring(rgba.indexOf("(")).split(","),
          r = parseInt(this.trim(inParts[0].substring(1)), 10),
          g = parseInt(this.trim(inParts[1]), 10),
          b = parseInt(this.trim(inParts[2]), 10);
      let outParts = [
        r.toString(16),
        g.toString(16),
        b.toString(16),
      ];
      outParts.forEach(function (part, i) {
        if (part.length === 1) {
          outParts[i] = '0' + part;
        }
      })
      return ('#' + outParts.join(''));
    },
    async formSubmit(e) {
      e.preventDefault();
      try {
        if (this.columnsValue.length > 0) {
          let headers = [];
          for (const header of this.columnsValue) {
            headers.push(header.value);
          }
          const componentSettings = {
            labels: {
              winners: {
                color: this.convertHexToRGBA(this.winnersColor),
                background_color: this.convertHexToRGBA(this.winnersBGColor),
                text: this.winnersLabel
              },
              surrogates: {
                color: this.convertHexToRGBA(this.surrogatesColor),
                background_color: this.convertHexToRGBA(this.surrogatesBGColor),
                text: this.surrogatesLabel
              },
              participants: {
                color: this.convertHexToRGBA(this.participantsColor),
              },
            },
            buttons: {
              start_raffle: {
                color: this.convertHexToRGBA(this.buttonColor),
                background_color: this.convertHexToRGBA(this.buttonBGColor),
                text: this.buttonText
              }
            }
          }
          EventBus.$emit('load');
          const data = await UsersAPI.updateRaffle(
            this.id,
            this.name,
            this.description,
            headers,
            parseInt(this.winnersNumber),
            parseInt(this.surrogatesNumber),
            componentSettings,
          ).then(async res => {
            if (this.bgImage) {
              const imageUrl = await UsersAPI.getPreSignedUrl(this.id, this.bgImage.type,'backgrounds');
              if (imageUrl) {
                const uploadImage = await UsersAPI.saveFile(imageUrl.presigned_url, this.bgImage);
                if (uploadImage) {
                  EventBus.$emit('endLoad');
                  const res = await this.$swal({
                    text: "Sorteo actualizado",
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                  });
                  if (res) {
                    this.$router.push("/raffles/list");
                  }
                }
              }
            } else {
              EventBus.$emit('endLoad');
              const res = await this.$swal({
                text: "Sorteo actualizado",
                icon: "success",
                confirmButtonColor: "#3085d6",
              });
              if (res) {
                this.$router.push("/raffles/list");
              }
            }
          });
        } else {
          EventBus.$emit('endLoad');
          this.$swal({
            title: "Error",
            text: "No ha seleccionado ninguna columna",
            icon: "error",
            confirmButtonColor: "#3085d6",
          });
        }
      } catch (error) {
        this.$swal({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.mt-1 {
  margin: 10px;
}
.vertical-container {
  margin-bottom: auto;
}
.at-color {
  background-color: var(--main-color);
}
.header-color {
  color: var(--main-color);
}

.multiselect__option {
  line-height: 13px;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  font-size: 13px !important;
}
.multiselect__tags {
  font-size: 13px !important;
}
</style>
