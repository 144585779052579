import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import CreateRaffle from './views/CreateRaffle.vue';
import UpdateRaffle from './views/UpdateRaffle.vue';
import Raffle from './views/Raffle.vue';
import ListRaffles from './views/ListRaffles.vue';
import WorkspaceSettings from './views/WorkspaceSettings.vue';
import { TokenStorage } from './tokenStorage';

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/raffles/list'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { title: 'Login' },
  },
  {
    path: '/raffle/:raffle_id',
    name: 'raffle',
    component: Raffle,
    meta: { Auth: true, title: 'Raffle' },
  },
  {
    path: '/raffles/create',
    name: 'create raffle',
    component: CreateRaffle,
    meta: { Auth: true, title: 'Create Raffle' },
  },
  {
    path: '/raffles/list',
    name: 'raffles-list',
    component: ListRaffles,
    meta: { Auth: true, title: 'Raffles List' },
  },
  {
    path: '/raffles/:id/update',
    name: 'update raffle',
    component: UpdateRaffle,
    meta: { Auth: true, title: 'Update Raffle' },
  },
  {
    path: '/workspace/settings',
    name: 'wSettings',
    component: WorkspaceSettings,
    meta: { Auth: true, title: 'Workspace Settings' },
  },
];

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.Auth && !TokenStorage.isAuthenticated()) {
    next({ path: '/login', query: { 'redirect-url': to.path } });
  } else if (TokenStorage.isAuthenticated()) {
    if (to.name) next();
    else next({name: 'raffles-list '})
  }  else {
    next();
  }
});

export default router;
