<template>
  <div class="vertical-container">
    <div id="default-img-modal" class="modal">
      <div class="modal-content">
        <h4 style="margin-bottom: 20px">Elija un fondo por defecto</h4>
        <v-drop :preload-bg="true" @upload="changeDefault" height="300px"></v-drop>
      </div>
      <div class="modal-footer">
        <a href="#!" style="background-color: #D9272E; margin-right: 20px" class="modal-close waves-effect waves-red btn">Cancelar</a>
        <a href="#!" style="margin-right: 20px" class="modal-close waves-effect waves-light btn" @click="changeDefaultImage" :class="!newDefault?'noClick':''">
          Cambiar Imagen
        </a>
      </div>
    </div>
    <h5 class="header-color">Configuración de Workspace</h5>
    <div class="row">
      <div class="col s8 offset-s2 m8 offset-m2 l6 offset-l3 card-panel">
        <form v-on:submit="formSubmit">
          <div class="row mt-1">
            <h5 class="header-color">Labels</h5>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center; margin-bottom: 0">
            <div class="input-field col s6" style="width: 100%">
              <input class="validate" id="winners" type="text" v-model="winnersLabel" required />
              <label for="winners">Texto Ganadores</label>
            </div>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center; margin-top: 0">
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de título ganadores</p>
              <input class="validate" type="color" id="winner-color" style="width: 100%" v-model="winnersColor">
            </div>
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de texto ganadores</p>
              <input class="validate" type="color" id="winner-bg-color" style="width: 100%" v-model="winnersBGColor">
            </div>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center; margin-top: 20px">
            <div class="input-field col s6" style="width: 100%">
              <input class="validate" id="surrogates" type="text" v-model="surrogatesLabel" required />
              <label for="surrogates">Texto Suplentes</label>
            </div>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center">
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de título suplentes</p>
              <input class="validate" type="color" style="width: 100%" v-model="surrogatesColor">
            </div>
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de texto suplentes</p>
              <input class="validate" type="color" id="surrogate-bg-color" style="width: 100%" v-model="surrogatesBGColor">
            </div>
          </div>
          <div class="row mt-1">
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de texto participantes</p>
              <input class="validate" type="color" id="participants-bg-color" style="width: 100%" v-model="participantsColor">
            </div>
          </div>
          <div class="row mt-1">
            <h5 class="header-color">Botones</h5>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center">
            <div class="input-field col s6" style="width: 100%">
              <input class="validate" id="button-label" type="text" v-model="buttonText" required />
              <label for="button-label">Texto de Iniciar Sorteo</label>
            </div>
          </div>
          <div class="row mt-1" style="display: flex; justify-content: center; align-items: center">
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de texto</p>
              <input class="validate" type="color" style="width: 100%" v-model="buttonColor">
            </div>
            <div class="input-field col s6 m-0">
              <p style="margin: 0; text-align: left; color: #9e9e9e; font-size: 1rem">Color de fondo</p>
              <input class="validate" type="color" style="width: 100%" v-model="buttonBGColor">
            </div>
          </div>
          <div class="row" style="margin-bottom: 0; margin-top: 40px">
            <a style="width: fit-content; margin-bottom: 20px" class="waves-effect waves-light btn modal-trigger" href="#default-img-modal">Cambiar imagen por defecto</a>
          </div>
          <div class="row">
            <div class="input-field col s4 offset-s4" style="display: flex; justify-content: center">
              <button class="btn at-color lighten-1 col s12" :class="loading ? 'noClick':''">{{ loading ? 'Cargando': 'Modificar' }}</button>
              <router-link :to="{name: 'raffles-list'}" class="btn at-color lighten-1 col s12" style="margin-left: 20px">Regresar</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script >
import { UsersAPI } from "@/store/api/users";
import Multiselect from "vue-multiselect";
import Papa from "papaparse";
import Dropify from "@/components/Dropify";
import {EventBus} from "@/utilities/event-bus";
import * as M from "materialize-css";

export default {
  name: "WorkspaceSettings",
  components: {
    Multiselect,
    'v-drop': Dropify
  },
  data() {
    return {
      winnersLabel: "",
      surrogatesLabel: "",
      buttonText: "",
      description: "",
      file: "",
      filename: "",
      columnsValue: [],
      columnsOptions: [],
      surrogatesNumber: "",
      winnersNumber: "",
      loading: false,
      bgImage: '',
      imageName: '',
      firstLoad: true,
      winnersColor: '#ffffff',
      winnersBGColor: '#ffffff',
      surrogatesColor: '#ffffff',
      surrogatesBGColor: '#ffffff',
      participantsColor: '#ffffff',
      buttonColor: '#ffffff',
      buttonBGColor: '#ffffff',
      newDefault: null,
    };
  },
  async created() {
    const settings = await UsersAPI.getWorkSpaceSettings()
    if (settings) {
      const labels = settings.components.labels
      if (labels) {
        this.winnersLabel = labels.winners.text
        this.surrogatesLabel = labels.surrogates.text
        this.winnersColor = this.rgbaToHex(labels.winners.color)
        this.winnersBGColor = this.rgbaToHex(labels.winners.background_color)
        this.surrogatesColor = this.rgbaToHex(labels.surrogates.color)
        this.surrogatesBGColor = this.rgbaToHex(labels.surrogates.background_color)
        this.participantsColor = this.rgbaToHex(labels.participants.color)
      }
      const buttons = settings.components.buttons
      if (buttons) {
        this.buttonText = buttons.start_raffle.text
        this.buttonColor = this.rgbaToHex(buttons.start_raffle.color)
        this.buttonBGColor = this.rgbaToHex(buttons.start_raffle.background_color)
      }
    }
    this.$nextTick(() => {
      M.updateTextFields();
    });
  },
  mounted() {
    const elems = document.querySelectorAll('.modal');
    const instances = M.Modal.init(elems);
  },
  methods: {
    convertHexToRGBA(hexCode){
      let hex = hexCode.replace('#', '');
      if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
      }
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r},${g},${b},1)`;
    },
    trim (str) {
      return str.replace(/^\s+|\s+$/gm,'');
    },
    rgbaToHex (rgba) {
      let inParts = rgba.substring(rgba.indexOf("(")).split(","),
      r = parseInt(this.trim(inParts[0].substring(1)), 10),
      g = parseInt(this.trim(inParts[1]), 10),
      b = parseInt(this.trim(inParts[2]), 10);
      let outParts = [
        r.toString(16),
        g.toString(16),
        b.toString(16),
      ];
      outParts.forEach(function (part, i) {
        if (part.length === 1) {
          outParts[i] = '0' + part;
        }
      })
      return ('#' + outParts.join(''));
    },
    changeDefault(fileList) {
      this.newDefault = fileList[0];
    },
    async changeDefaultImage() {
      EventBus.$emit('load');
      try {
        const preSignedUrl = await UsersAPI.getDefaultImagePreSignedUrl(this.newDefault.type);
        if (preSignedUrl) {
          const newDefImg = await UsersAPI.saveFile(preSignedUrl.presigned_url, this.newDefault);
          if (newDefImg) {
            this.$swal({
              text: "Imagen por defecto cambiado con éxito",
              icon: "success",
              confirmButtonColor: "#3085d6",
            });
          } else {
            this.$swal({
              title: 'Error',
              text: 'Ocurrió un error al subir la imagen',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
          }
        } else {
          this.$swal({
            title: 'Error',
            text: 'Ocurrió un error al subir la imagen',
            icon: 'error',
            confirmButtonColor: '#3085d6',
          });
        }
        EventBus.$emit('endLoad');
      }
      catch (e) {
        this.$swal({
          title: 'Error',
          text: 'Ocurrió un error al subir la imagen',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
        EventBus.$emit('endLoad');
      }
    },
    async formSubmit(e) {
      e.preventDefault();
      const newSettings = {
        components: {
          labels: {
            winners: {
              color: this.convertHexToRGBA(this.winnersColor),
              background_color: this.convertHexToRGBA(this.winnersBGColor),
              text: this.winnersLabel
            },
            surrogates: {
              color: this.convertHexToRGBA(this.surrogatesColor),
              background_color: this.convertHexToRGBA(this.surrogatesBGColor),
              text: this.surrogatesLabel
            },
            participants: {
              color: this.convertHexToRGBA(this.participantsColor),
            },
          },
          buttons: {
            start_raffle: {
              color: this.convertHexToRGBA(this.buttonColor),
              background_color: this.convertHexToRGBA(this.buttonBGColor),
              text: this.buttonText
            }
          }
        }
      }
      try {
        this.loading = true;
        const data = await UsersAPI.updateWorkSpaceSettings(newSettings);
        this.loading = false;
        this.$swal({
          title: "Cambio exitoso",
          icon: "success",
          confirmButtonColor: "#3085d6",
        }).then(r => {
          this.$router.push("/raffles/list");
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.$swal({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.noClick{
  opacity: 0.5;
  pointer-events: none;
}
.mt-1 {
  margin: 10px;
}
.vertical-container {
  margin-bottom: auto;
}
.at-color {
  background-color: var(--main-color);
}
.header-color {
  color: var(--main-color);
}

.multiselect__option {
  line-height: 13px;
}
.multiselect, .multiselect__input, .multiselect__single {
  font-size: 13px !important;
}

.multiselect--above.multiselect--active .multiselect__current, .multiselect--above.multiselect--active .multiselect__input, .multiselect--above.multiselect--active .multiselect__tags {
  font-size: 13px !important;
}
.multiselect__tags {
  font-size: 13px !important;
}
.m-0 {
  margin: 0;
}
.noClick {
  opacity: 0.5;
  pointer-events: none;
}
</style>