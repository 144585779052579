import axios from 'axios';
import { TokenStorage } from './tokenStorage';
import { UsersAPI } from './store/api/users';
import { router } from './router';

const REFRESH_TOKEN_URL = `${process.env.VUE_APP_API_BASE_URL}/api/users/refresh-token`;

axios.interceptors.request.use(
    (config) => {
    if (TokenStorage.isAuthenticated()) {
        const token = TokenStorage.getToken();
        config.headers.common = {
            Authorization: `Bearer ${token}`,
        };
    }
    return config;
});

axios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    if (error.response.status !== 401) {
        return Promise.reject(error);
    }

    if (error.config.url === REFRESH_TOKEN_URL) {
        TokenStorage.clear();
        UsersAPI.deleteUserId();
        router.push({ path: '/login' });
        return Promise.reject(error);
    }

    if (!error.response.data.type || error.response.data.type !== 'jwt') {
        return Promise.reject(error);
    }


    try {
        const newToken = await TokenStorage.getNewToken();
        const config = error.config;
        config.headers.Authorization = `Basic Auth ${newToken}`;

        return new Promise(async (resolve, reject) => {
            const response = await axios.request(config);
            resolve(response);
        // tslint:disable-next-line:no-shadowed-variable
        }).catch((error) => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
});
