<template>
<div>
  <RandomSelector v-if="raffle_id" :raffle_id="raffle_id"></RandomSelector>
</div>
</template>

<script>
import RandomSelector from '../components/RandomSelector.vue';
export default {
  name: "Raffle",
  components:{
    RandomSelector
  },
  data(){
    return{
      raffle_id: ''
    }
  },
  created() {
    this.raffle_id = this.$router.currentRoute.params.raffle_id;
  }
}
</script>

<style scoped>

</style>
