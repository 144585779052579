<template>
  <div>
    <div style="display: grid; align-items: center; padding: 0 6%" class="row btn-db">
      <div style="grid-column: 1; grid-row: 1; text-align: right;">
        <p><span style="font-weight: bold">Usuario: </span>{{username}}</p>
        <p><span style="font-weight: bold">Workspace: </span>{{workspace}}</p>
        <button style="background-color: #D9272E; margin-left: 20px" class="waves-effect waves-red btn" @click="logout">Cerrar Sesión</button>
      </div>
      <div style="grid-column: 1; grid-row: 1; display: flex; flex-direction: column">
        <router-link style="float: left; width: fit-content;margin-bottom: 20px" class="waves-effect waves-light btn" :to="{name: 'wSettings'}">Configuración de workspace</router-link>
        <router-link style="float: left; width: fit-content" class="waves-effect waves-light btn" to="create">Crear Base de Datos</router-link>
      </div>
    </div>
    <RaffleList @do-raffle="goToRaffle" @download-raffle="createExcel"></RaffleList>
  </div>
</template>

<script>
import RaffleList from '../components/RaffleList.vue';
import { TokenStorage } from "@/tokenStorage";
const ExcelJS = require('exceljs');
import { saveAs } from 'file-saver';
import {EventBus} from "@/utilities/event-bus";
import axios from "axios";

export default {
  name: "ListRaffles",
  components: {
    RaffleList,
  },
  data() {
    return {
      headers: [],
      winners: [],
      surrogates: []
    }
  },
  methods: {
    async getWinner(raffle_id) {
      EventBus.$emit("load");
      return axios.get(`${process.env.VUE_APP_API_BASE_URL}/${raffle_id}/results`);
    },

    async getSamples(raffle_id) {
      EventBus.$emit("load");
      return axios
          .get(`${process.env.VUE_APP_API_BASE_URL}/${raffle_id}/sample`);
    },

    addCellBorder(cell) {
      cell.border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
    },
    formatHeaderCell(cell) {
      this.addCellBorder(cell);
      cell.font = {
        name: "Nunito",
        size: 10,
        bold: true,
      };
      cell.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'F3F3F3'},
      }
      cell.alignment = { wrapText: true }
    },

    formatContentCell(cell) {
      this.addCellBorder(cell);
      cell.font = {
        name: "Nunito",
        size: 10,
      };
      cell.alignment = { 'horizontal': 'left', wrapText: true }
    },

    async createExcel(raffle){
      try {
        const raffleData = await this.getSamples(raffle.id);
        this.headers = raffleData.data.headers;
      } catch (e) {
        console.log(e)
      } finally {
        EventBus.$emit("endLoad");
      }

      try {
        const winnerData = await this.getWinner(raffle.id);
        this.winners = winnerData.data.winners;
        this.surrogates = winnerData.data.surrogates;
      } catch (e) {
        console.log(e);
      } finally {
        EventBus.$emit("endLoad");
      }

      const ExcelJSWorkbook = new ExcelJS.Workbook();
      let worksheet = ExcelJSWorkbook.addWorksheet();
      worksheet.name = 'Información General';

      const titleCell = worksheet.getCell("A1");

      titleCell.font = {
        name: "Nunito",
        size: 22
      };

      titleCell.value = 'Sorteos Bancatlan';

      worksheet.getColumn(1).width = 32;
      worksheet.getColumn(2).width = 40;

      const createdMap = {
        "created": "Creada",
        "processing-data": "Procesando data",
        "data-processing-failed": "Falló procesamiento",
        "data-processed": "Data procesada",
        "raffle-started": "Rifa iniciada",
        "raffled": "Rifado",
        "closed": "Cerrado",
        "canceled": "Anulado",
      };

      let colLabels = {
        3: {label: 'Workspace', value: this.workspace},
        4: {label: 'Usuario', value: this.username},
        5: {label: 'Nombre', value: raffle.name},
        6: {label: 'Estado', value: createdMap[raffle.status]},
        7: {label: 'Descripción', value: raffle.description},
        8: {label: 'Número de registros', value: raffle.number_of_records},
        9: {label: 'Fecha de creación', value: new Date(raffle.created_at * 1000)},
        10: {label: 'Fecha de inicio', value: new Date(raffle.raffled_at * 1000)},
        11: {label: 'Fecha de finalización', value: raffle.closed_at ? new Date(raffle.closed_at * 1000) : ''},
      }

      for (let i = 3; i < 12; i ++) {
        let labelCell = worksheet.getCell("A" + i);
        this.formatHeaderCell(labelCell);
        labelCell.value = colLabels[i].label;

        let contentCell = worksheet.getCell("B" + i);
        this.formatContentCell(contentCell);
        contentCell.value = colLabels[i].value;
      }

      //sheet de resultados

      worksheet = ExcelJSWorkbook.addWorksheet();
      worksheet.name = 'Resultados';

      this.headers.unshift('Tipo');
      const headerRow = worksheet.getRow(1);
      for (let i = 0; i < this.headers.length; i ++) {
        worksheet.getColumn(i+1).width = 40;
        let cell = headerRow.getCell(i + 1);
        cell.value = this.headers[i];
        this.formatHeaderCell(cell);
      }

      let rowOffset = 2;
      for (let i = 0; i < this.winners.length; i++) {
        let row = worksheet.getRow(rowOffset + i);
        this.winners[i].unshift('Ganador');
        for (let j = 0; j < this.winners[i].length; j++) {
          let cell = row.getCell(j + 1);
          cell.value = this.winners[i][j];
          this.formatContentCell(cell);
        }
      }

      rowOffset += this.winners.length;
      for (let i = 0; i < this.surrogates.length; i++) {
        let row = worksheet.getRow(rowOffset + i);
        this.surrogates[i].unshift('Suplente');
        for (let j = 0; j < this.surrogates[i].length; j++) {
          let cell = row.getCell(j + 1);
          cell.value = this.surrogates[i][j];
          this.formatContentCell(cell);
        }
      }

      const buffer = await ExcelJSWorkbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), "Resultados " + raffle.name + ".xlsx");
    },
    goToRaffle(raffle){
      this.$router.push({
        name: 'raffle',
        params: {
          raffle_id: raffle.raffle_id,
          raffleAgain: raffle.raffleAgain
        }
      })
    },
    logout(){
      TokenStorage.clear();
      location.reload();
    }
  },
  computed: {
    username() {
      return this.$store.state.username;
    },
    workspace(){
      return this.$store.state.workspace;
    }
  }
}
</script>

<style scoped>
.btn-db{
  margin-right: 4rem;
}
.noClick {
  opacity: 0.5;
  pointer-events: none;
}
</style>
