import { UsersAPI } from "./api/users";
import { getDefaultState } from "./state";

export const mutations = {
   
    clearState(state) {
        Object.assign(state, getDefaultState())
    },

    setUsername(state, userName) {
        state.username = userName;
        localStorage.setItem('username', userName);
    },
    setWorkSpace(state, workSpace) {
        state.workspace = workSpace;
        localStorage.setItem('workspace', workSpace);
    },
    initialiseStore(state) {
        const username = localStorage.getItem('username');
        if (username) {
            state.username = username;
        }
        const workspace = localStorage.getItem('workspace');
        if (workspace) {
            state.workspace = workspace;
        }
    },
};
function setPath(object, path, value) {
    path
        .split('.')
        .reduce((o, p, i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)
}