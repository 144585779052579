














































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import { EventBus } from "@/utilities/event-bus";
import * as M from "materialize-css";

@Component
export default class RaffleList extends Vue {
  private raffles: any = [];
  private createdMap: any = {
    "created": "Creada",
    "processing-data": "Procesando data",
    "data-processing-failed": "Falló procesamiento",
    "data-processed": "Data procesada",
    "raffle-started": "Rifa iniciada",
    "raffled": "Rifado",
    "closed": "Cerrado",
    "canceled": "Anulado",
  };
  private colorMap: any = {
    "created": "lime darken-1",
    "processing-data": "yellow darken-1",
    "data-processing-failed": "red darken-3",
    "data-processed": "green darken-1",
    "raffle-started": "teal darken-1",
    "raffled": "light-blue darken-4",
    "closed": "blue-grey lighten-2",
    "canceled": "orange darken-4",
  };
  public destroyTooltips() {
    const tooltips = document.querySelectorAll(".tooltipped");

    tooltips.forEach((tooltip) => {
      const instance = M.Tooltip.getInstance(tooltip);
      if (instance) {
        instance.destroy();
      }
    });
  }
  public async created() {
    await this.getRaffles();
    const elems = document.querySelectorAll(".tooltipped");
    const instances = M.Tooltip.init(elems, {});
  }
  public unixToDate(unix_timestamp: number): string {
    return new Date((unix_timestamp - 21600) * 1000)
      .toISOString()
      .slice(0, 16)
      .replace("T", " ");
  }
  public goToRaffle(raffle_id: string, raffleAgain: boolean): void {
    this.destroyTooltips();
    this.$emit("do-raffle", { raffle_id, raffleAgain });
  }
  public reRaffle(raffle_id: string, raffleAgain: boolean): void {
    const ref = this;
    this.$swal({
      title: "Confirmar volver a rifar",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#645b5b",
      confirmButtonText: "Rifar",
      cancelButtonText: "Cancelar",
      buttonsStyling: true,
    }).then((isConfirm: any) => {
      if (isConfirm.value) {
        ref.goToRaffle(raffle_id, raffleAgain);
      }
    });
  }
  public async deleteRaffle(raffle_id: string): Promise<any> {
    this.$swal({
      title: "Confirmar archivado",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#645b5b",
      confirmButtonText: "Archivar",
      cancelButtonText: "Cancelar",
      buttonsStyling: true,
    }).then((isConfirm: any) =>{
      if (isConfirm.value) {
        // this.destroyTooltips();
        EventBus.$emit("load");
        axios
          .delete(`${process.env.VUE_APP_API_BASE_URL}/${raffle_id}/`)
          .then((response: any) => {
            this.getRaffles();
            EventBus.$emit("endLoad");
          })
          .catch((err: any) => {
            console.log(err);
            EventBus.$emit("endLoad");
          });
      }
    }); 
  }
  public async closeRaffle(raffle_id: string): Promise<any> {
    this.$swal({
      title: "Confirmar finalización",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#645b5b",
      confirmButtonText: "Finalizar",
      cancelButtonText: "Cancelar",
      buttonsStyling: true,
    }).then((isConfirm: any) => {
      if (isConfirm.value) {
        // this.destroyTooltips();
        EventBus.$emit("load");
        axios
          .patch(`${process.env.VUE_APP_API_BASE_URL}/${raffle_id}/`,  { status: "closed" })
          .then((response: any) => {
            this.getRaffles();
            EventBus.$emit("endLoad");
          })
          .catch((err: any) => {
            console.log(err);
            EventBus.$emit("endLoad");
          });
      }
    });  
  }
  public async cancelRaffle(raffle_id: string): Promise<any> {
    this.$swal({
      title: "Confirmar anulación",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#645b5b",
      confirmButtonText: "Anular",
      cancelButtonText: "Cancelar",
      buttonsStyling: true,
    }).then((isConfirm: any) => {
      if (isConfirm.value) {
        // this.destroyTooltips();
        EventBus.$emit("load");
        axios
          .patch(`${process.env.VUE_APP_API_BASE_URL}/${raffle_id}/`,  { status: "canceled" })
          .then((response: any) => {
            this.getRaffles();
            EventBus.$emit("endLoad");
          })
          .catch((err: any) => {
            console.log(err);
            EventBus.$emit("endLoad");
          });
      }
    }); 
  }
  public async getRaffles(): Promise<any> {
    EventBus.$emit("load");
    return axios
      .get(`${process.env.VUE_APP_API_BASE_URL}`)
      .then((response: any) => {
        this.raffles = response.data;
        EventBus.$emit("endLoad");
      })
      .catch((error: any) => {
        EventBus.$emit("endLoad");
        console.log(error);
      });
  }
}
