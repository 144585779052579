<template>
  <div class="vertical-container">
    <h5 class="header-color">Login</h5>
    <div class="row">
      <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4 card-panel">
        <form v-on:submit="formSubmit" class="login-form">
          <div class="row input-field col s12 ws-select" style="padding: 0; display: flex; justify-content: center; align-items: center">
            <i class="material-icons" style="font-size: 2rem; margin: 0 5px">domain</i>
            <select style="margin-left: 5px" v-model="workspace">
              <option value="" disabled selected>{{'Seleccione un workspace'}}</option>
              <option v-for="w in workspaces" :value="w.id">{{w.name}}</option>
            </select>
          </div>
          <div class="row mt-1">
            <div class="input-field col s12">
              <i class="material-icons prefix">person_outline</i>
              <input class="validate" id="username" type="text" v-model="username" required />
              <label for="username">Usuario</label>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <i class="material-icons prefix">lock_outline</i>
              <input id="password" type="password" v-model="password" required />
              <label for="password">Contraseña</label>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <button :class="!workspace ? 'noClick':''" class="btn at-color lighten-1 col s12">
                Iniciar sesión
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { UsersAPI } from '../store/api/users';
import * as M from "materialize-css";

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      workspace: '',
      workspaces: []
    };
  },
  async created() {
    await this.getWorkSpaces();
    var elems = document.querySelectorAll('select');
    var instances = M.FormSelect.init(elems);
  },
  methods: {
    async getWorkSpaces(){
      const data = await UsersAPI.getWorkSpaces();
      if (data) {
        this.workspaces = data;
      } else{
        this.$swal({
          title: 'Error',
          text: 'Ocurrió un error al obtener workspaces',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
      }
    },
    async formSubmit(e) {
      e.preventDefault();
      try {
        const data = await UsersAPI.login(this.username, this.password, this.workspace);
        if (data) {
          this.$store.commit('setUsername', data.user.full_name);
          this.$store.commit('setWorkSpace', data.workspace.name);
          if (this.$route.query['redirect-url']) {
            this.$router.push(this.$route.query['redirect-url']);
          } else {
            this.$router.push('/raffles/list');
          }
        }
      } catch (error) {
        console.log(error);
        this.$swal({
            title: 'Error',
            text: 'Usuario o contraseña incorrectos.',
            icon: 'error',
            confirmButtonColor: '#3085d6',
          });
      }
    },
  },
};
</script>
<style scoped>
.noClick {
  opacity: 0.5;
  pointer-events: none;
}
.mt-1 {
  margin-top: 15px;
}
.vertical-container {
  margin-top: 2%;
  margin-bottom: auto;
}
.at-color {
  background-color: var(--main-color);
}
.header-color {
  color: var(--main-color);
}
</style>
<style lang="css">
.ws-select .select-wrapper {
  width: 100%;
}
/*.ws-select .selected span {*/
/*  color: var(--main-color);*/
/*}*/
</style>
